<template>
  <div>
    <CCard>
      <CCardBody>
        <!-- <div class="clearfix">
      <button type="button" class="btn btn-primary float-xl-right" @click="navToNewSymptoms">New Item</button>
    </div> -->

        <CDataTable
          :items="items"
          :fields="fields"
          striped
          column-filter
          table-filter
          items-per-page-select
          :items-per-page="20"
          sorter
          ref="vuetable"
          pagination
        >
          <template #index="data">
            <td>
              {{ data.index + 1 }}
            </td>
          </template>

          <template #createdTime="{ item }">
            <td class="font-weight-bold" v-if="item.createdTime">
              {{
                item.createdTime
                  | dateParse()
                  | dateFormat("MMM D, YYYY hh:mm A")
              }}
            </td>
            <td v-else>--</td>
          </template>
          <template #renewalDate="{ item }">
            <td class="font-weight-bold" v-if="item.renewalDate">
              {{
                item.renewalDate
                  | dateParse("YYYY.MM.DD")
                  | dateFormat("MMM D, YYYY ")
              }}
            </td>
            <td v-else>--</td>
          </template>
          <template #firstName="{ item }">
            <td class="font-weight-bold" v-if="item.firstName && item.lastName">
              {{ item.firstName }} {{ item.lastName }}
            </td>
            <td v-else>--</td>
          </template>

          <!-- <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                  <CDropdownItem :to="{name: 'Item Tracker', params: {categoryId: item.categoryId,subGroupId: item.subGroupId, mode: 'view'}}">View </CDropdownItem> -->
          <!-- <CDropdownItem :to="{name: 'JobTypes', params: {departmentId: item.departmentId, mode: 'view'}}">Job Types </CDropdownItem> -->
          <!-- <CDropdownItem :to="{name: 'DepartmentUsers', params: {departmentId: item.departmentId,subGroupId: item.subGroupId, mode: 'view'}}">Department Users </CDropdownItem>
                <CDropdownItem :to="{name: 'DepartmentCategories', params: {departmentId: item.departmentId,subGroupId: item.subGroupId,groupId: item.groupId}}">Categories</CDropdownItem> 
                <ConfirmationModal
                      :parent="$refs.modalArea"
                      :description="'Are you sure to Delete'"
                      @on:ok="deleteDepartment(item.departmentId)"
                    ></ConfirmationModal>

                </CDropdown>

        
        </td>
      </template> -->
        </CDataTable>
      </CCardBody>
      <div ref="modalArea"></div>
      <CCardFooter align="right">
        <CButton
          class="mr-2"
          type="Cancel"
          size="sm"
          color="danger"
          @click="cancel()"
        >
          <CIcon name="cil-ban" /> Cancel
        </CButton>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
const fields = [
  "index",
  {
    key: "firstName",
    label: "Name",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },
  { key: "itemName", _classes: "font-weight-bold", _style: "min-width:200px" },
  {
    key: "itemNumber",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },
  { key: "remarks", _classes: "font-weight-bold", _style: "min-width:200px" },
  {
    key: "renewalDate",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },
  {
    key: "createdTime",
    label: "Created Date & Time",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },
  //  { key: "show_details",
  //   label: "",
  //   _style: "width:1%",
  //   sorter: false,
  //   filter: false,
  // },
];
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "Item Trackers",
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getDepartments() {
      console.log("item category id", this.$route.params.categoryId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/item/tracks/" +
          this.$route.params.categoryId
      )
        .then((response) => response.json())
        .then(
          (data) => (
            (this.items = data), console.log("data from api", this.items)
          )
        );
    },

    cancel() {
      this.$router.push({
        name: "Department Categories",
        params: { departmentId: this.$route.params.departmentId },
      });
    },
    // deleteDepartment(departmentId) {
    //   console.log("delete called with", departmentId);
    //   this.apiGetSecure(
    //     process.env.VUE_APP_API_HOST +
    //       "/emapp/web/secure/delete/department/" +
    //      departmentId
    //   )
    //     .then((response) => response.json())
    //     .then((data) => {
    //       this.getDepartments();
    //          this.$nextTick(() => {
    //          this.$refs.vuetable.refresh();
    //        });
    //        window.location.reload();
    //     });
    // },
    // navToNewSymptoms(){
    //    this.$router.push({ name: "Item Tracker", params: {categoryId: this.$route.params.categoryId, mode: "new" }});
    // }
  },
  mounted() {
    this.getDepartments();
  },
};
</script>